<template>
 <div>
   <v-row class="pa-0">
     <v-col cols="12" class="mx-auto">
       <div class="text-center pt-10">
         <lottie-player src="/lotties/bot.json" background="transparent" speed="1.2" class="mx-auto bot-figure" loop autoplay></lottie-player>
         <h1 class="text-center font-weight-bold text-h4 mt-5 text-uppercase">Agent Control</h1>
       </div>
     </v-col>
     <v-col cols="10" md="6" lg="5" class="mx-auto">
       <v-progress-linear class="my-5" rounded indeterminate />
       <h4 class="text-center" :class="(errors ? 'red--text' : '')" v-if="message">{{message}}</h4>
     </v-col>
   </v-row>
 </div>
</template>

<script>
export default {
  name: "AutoLogin",
  data(){
    return {
      loading: false,
      token: null,
      access_token: null,
      access_token_type: null,
      access_expires_at: null,
      message: null,
      errors: null,
    }
  },
  mounted() {
    this.checkAuth()
  },
  methods:{
    checkAuth() {
      this.token = this.$route.query.token
      this.access_token = this.$route.query.access_token
      this.access_token_type = this.$route.query.access_token_type
      this.access_expires_at = this.$route.query.access_expires_at
      this.login()
    },
    login() {
      this.message = "Loading data and trying to login...";
      this.errors = null
      this.loading = true
      this.$http({
        method: 'get',
        url: '/v1/auth/user',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.access_token_type} ${this.access_token}`,
        },
        data: {
          full_name: this.full_name,
          new_password: this.new_password,
          new_password_confirmation: this.new_password_confirmation,
        },
      }).then(response => {
        this.message = null
        const access = {
          'token': this.access_token,
          'token_type': this.access_token_type,
          'expires_at': this.access_expires_at
        };
        this.$store.commit('SetUser', response.data.user)
        this.$store.commit('SetAccess', access)
        this.$router.push({
          name: 'agents-control-chat',
          params: {
            token: this.token
          }
        });
      }).catch(err => {
        this.message = "Something went wrong, please reload the page and try again"
        this.errors = err.response.data
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss">
.bot-figure {
  max-width: 400px;
  max-height: 400px;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 80px;
}
.red--text{
  color: orangered;
}
</style>
